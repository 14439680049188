.loading-screen {
    background: #cfddff; 
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    border: .2rem solid #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
}

.text {
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

.progress-bar {
    width: 100%;
    height: 2rem;
    background: #fff;
    border-radius: .5rem;
    overflow: hidden;
    display: flex;

    .bar {
        height: 100%;
        width: 0%;
        background: #496bff;
    }
}