*,
*::before,
*::after {
    box-sizing: border-box;
}

body { 
    background: #d8d8d8;
    background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%); //linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%);
    margin: 0; 
    font-family: Arial, Helvetica, sans-serif;
    height: 100vh;
}

body::after {
    //content: url('../img/prizemie.png') url('../img/pos1.png') url('../img/pos2.png');
    display: block;
    position: fixed;
    top: -9999;
    left: -9999;
}

.container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
}

@media only screen and (max-width: 600px) {
    .container {
        padding: 0;
    }
}