.leaflet-container { 
    background: #cfddff !important; 
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    border: .2rem solid #fff;
}

.frame {
    position: relative;
    box-shadow: 0 1px 2px rgba(0,0,0,.2);
    border-radius: 1rem;
    /*border: .2rem solid #d00;*/
    border: .2rem solid #fff;
    max-width: 85rem;
    max-height: 50rem;
    width: 100%;
    height: 100%;
    padding: 2rem;
    background: #8aa0ff;
    background: radial-gradient(circle, #acbcff 60%, #496bff 100%); //radial-gradient(circle, rgba(138,160,255,1) 60%, rgba(73,107,255,1) 100%);

    .bg {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        border-radius: 1rem;
        padding: 2rem;
        width: 100%;
        height: 100%;
        background: #fff;
    }

    .author-link {
        z-index: 500;
        position: absolute;
        bottom: .4rem;
        left: 50%;
        transform: translate(-50%);
        color: #000333;
        text-decoration: none;
        text-align: center;

        &:hover {
            text-decoration: underline;
        }
    }
}

.logo {
    z-index: 500;
    position: absolute;
    top: -2.1rem;
    left: -2.1rem;
    width: 10rem;
}


.leaflet-container .control-list {
    display: flex;

    border: 2px solid rgba(0,0,0,0.2);
    background-clip: padding-box;
    border-radius: 4px;
    overflow: hidden;
}

.leaflet-container .control-item {
    border: none;
    background-color: #fff;
    border-right: 1px solid #ccc;
    line-height: 26px;
    display: block;
    text-align: center;
    text-decoration: none;
    color: black;
    font-weight: bold;
    padding: 4px 10px;
    cursor: pointer;
    font-family: inherit;
}
.leaflet-container .control-item:last-child {
    border-right: none;
}

.leaflet-container .control-item:hover {
    background-color: #f4f4f4;
}

.leaflet-container .control-item:disabled {
    cursor: default;
    background-color: #f4f4f4;
    color: #bbb;
}


@media only screen and (max-width: 1000px) {
    .frame {
        padding: 1.5rem;

        .author-link {
            bottom: .2rem;
            font-size: .9rem;
        }
    }
    
    .logo {
        width: 8rem;
    }
}

@media only screen and (max-width: 600px) {
    .frame {
        padding: 0;
        border: none;
        border-radius: 0;
    }

    .leaflet-container {
        border: none;
        border-radius: 0;
    }

    .frame .bg {
        border-radius: 0;
    }
    
    .logo {
        top: auto;
        bottom: .75rem;
        left: .75rem;
        width: 4rem;
    }
}

.tooltip {
    font-size: 1rem;
}